<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字：'>
                        <el-input v-model.trim='filters.keyWord' placeholder='请输入姓名/手机号/身份证号' clearable></el-input>
                    </el-form-item>
                    <el-form-item label=''>
                        <el-input v-model.trim='filters.unitWord' placeholder='请输入单位名称' clearable></el-input>
                    </el-form-item>
                    <el-form-item label='是否已缴费：'>
                        <el-select v-model="filters.isPay" clearable>
                            <el-option
                                v-for="item in isPayArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='行业类别：'>
                        <el-select v-model="filters.TradeType" clearable>
                            <el-option
                                v-for="item in TradeTypeArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label='是否启用：'>
                        <el-select v-model="filters.IsEnable" clearable>
                            <el-option
                                v-for="item in isPayArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table
        border
        v-loading='listLoading'
        :data="tableData"
        highlight-current-row
        @current-change='selectCurrentRow'
        style="width: 100%">
            <el-table-column type="index" width="55" label='序号' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="Name" width="" label='姓名' align='center'></el-table-column>
            <el-table-column prop="Gender" width="70" label='性别' align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.Gender == 0?"success":"primary"'>{{scope.row.Gender == 0?'女':'男'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="CardNo" width="180" label='身份证号码' align='center'></el-table-column>
            <el-table-column prop="Phone" width="120" label='手机号' align='center'></el-table-column>
            <!-- <el-table-column prop="Province" width="" label='省份' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="City" width="" label='城市' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="Region" width="" label='县区' align='center' show-overflow-tooltip></el-table-column> -->
            <el-table-column  prop="PostAddress" label="教材邮寄地址" min-width="140" align='center'>
                <template slot-scope='scope'>
                    <!-- <span v-if="scope.row.IsInformationPerfect && scope.row.PostAddress">{{scope.row.PostAddress}}</span>
                    <span v-if="scope.row.IsInformationPerfect && !scope.row.PostAddress">已邮寄教材，无需重复邮寄</span> -->
                    {{scope.row.PostAddress == "选择省" ? "-" : scope.row.PostAddress}}
                </template>
            </el-table-column>
            <el-table-column prop="UnitName" width="120" label='单位名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="Address" width="120" label='单位地址' align='center' show-overflow-tooltip></el-table-column>                  
            <el-table-column prop="StationName" width="100" label='岗位名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="TradeName" width="100" label='行业名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="AgencyName" width="115" label='培训机构名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="IsDefault" width="140" label='是否当前默认课程' align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsDefault == true ? "success" : "warning"'>{{scope.row.IsDefault == true ? "是" : "否"}}</el-tag>
                </template>
            </el-table-column>           
            <el-table-column prop="LearningEnrollNo" width="" label='学习报名单号' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="StudentLearningAccountID" width="130" label='学员学习账号ID' align='center'></el-table-column>
            <el-table-column prop="TeachingMaterialName" width="120" label='学习教材名称' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column prop="LearningDuration" width="120" label='学习总时长' align='center'></el-table-column>
            <el-table-column prop="VideoLearningDuration" width="120" label='录播视频时长' align='center'></el-table-column>
            <el-table-column prop="MockExamDuration" width="120" label='模拟考试时长' align='center'></el-table-column>
            <el-table-column prop="FreeExerciseDuration" width="120" label='自由练习时长' align='center'></el-table-column>
            <el-table-column prop="PayAmount" width="120" label='需缴费金额' align='center'></el-table-column>
            <el-table-column prop="IsPay" width="100" label='是否缴费' align='center'>
                <template slot-scope='scope'>
                    <el-tag type='primary' v-if='scope.row.IsPay == 0'>无需缴费</el-tag>
                    <el-tag type='success' v-if='scope.row.IsPay == 1'>已缴费</el-tag>
                    <el-tag type='warning' v-if='scope.row.IsPay == 2'>未缴费</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="PayTime" width="130" label='缴费时间' align='center' show-overflow-tooltip :formatter="formatCreateTime"></el-table-column>
            <el-table-column prop="PayWay" width="100" label='缴费方式' align='center'>
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.PayWay == 0 ? "primary" : "warning"'>{{scope.row.PayWay == 0 ? '支付宝' : '微信'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="PayOrderNo" width="" label='缴费单号' align='center' show-overflow-tooltip></el-table-column>           
            <el-table-column prop="IsEnable" width="100" label='启用标识' align='center' fixed="right">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable == true ? "success" : "warning"'>{{scope.row.IsEnable == true ? "启用" : "禁用"}}</el-tag>
                </template>
            </el-table-column>           
            <el-table-column prop="CreateTime" width="100" label='创建时间' align='center' show-overflow-tooltip :formatter="formatCreateTime" fixed="right"></el-table-column>
            <el-table-column  prop="SetBookStatus" label="教材邮寄状态" min-width="120" align='center' fixed="right">
                <template slot-scope='scope'>
                    <!-- <el-tag type='warning' effect="plain" v-if='scope.row.Mailstatus == -2'>待发起寄出</el-tag>
                    <el-tag type='' effect="plain" v-if='scope.row.Mailstatus == -1'>无需邮寄</el-tag>
                    <el-tag type='' v-else-if='scope.row.Mailstatus == 1'>待邮寄</el-tag>
                    <el-tag type='info' v-else-if='scope.row.Mailstatus == 2'>待发货</el-tag>
                    <el-tag type='warning' v-else-if='scope.row.Mailstatus == 3'>待签收</el-tag>
                    <el-tag type='success' v-else-if='scope.row.Mailstatus == 4'>已签收</el-tag> -->
                    <el-tag type='' v-if='scope.row.SetBookStatus == 1'>同步成功</el-tag>
                    <el-tag type='danger' v-else>同步失败</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="Remarks" width="100" la/bel='备注' align='center' show-overflow-tooltip fixed="right"></el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.dataCount">
        </el-pagination>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import util from "../../../util/date";
import {getLearnEnrollListPage,disableStudentLearningEnroll,manuallySynchronizeLearningRecords,getDataDictionaryList,setBookLearningEnrollPostInfo} from '@/api/api'
import Qs from 'qs';
export default {
    components: { Toolbar },
    data(){
        return{
            filters:{
                keyWord: '',
                unitWord: '',
                isPay: '',
                TradeType: '',
                IsEnable: '',
            },
            isPayArr: [
                {value: 1, name: "是"},
                {value: 2, name: "否"},
            ],
            TradeTypeArr: [],
            buttonList: [],
            listLoading:false,
            tableData:[],
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd hh:mm:ss");
        },
        // 分页相关操作
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            window.sessionStorage.setItem('pagination',JSON.stringify(value))
            this.pages.pageIndex = value
            this.getData()
        },
        // 获取列表数据
        getData(){
            var params = {
                keyWord: this.filters.keyWord,
                unitName: this.filters.unitWord,
                isPay: this.filters.isPay,
                tradeID: this.filters.TradeType,
                isEnable: this.filters.IsEnable,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            this.listLoading = true
            getLearnEnrollListPage(params).then(res =>{
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.dataCount = res.data.Response.DataCount
                }else{
                    this.$message.error(res.data.Message)
                }
            }).catch(() => {
                this.listLoading = false
            })
        },
        // 查询
        getLearnEnrollListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 禁用
        disableStudentLearningEnroll(){
            var row = this.currentRow
            if(row && JSON.stringify(row) != '{}'){
                this.$confirm('确定禁用？',"提示",{}).then(() => {
                    var params = {
                        learnEnrollID: row.ID,
                    }
                    disableStudentLearningEnroll(params).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                })
            }else{
                return this.$message.warning('请选择要操作的一行数据')
            }           
        },
        // 同步学习时长
        syncStudyTimer() {
            var row = this.currentRow
            if(row && JSON.stringify(row) != '{}'){
                this.$confirm('确定同步学习时长？',"提示",{}).then(() => {
                    var params = {
                        Id: row.ID,
                    }
                    manuallySynchronizeLearningRecords(params).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                })
            }else{
                return this.$message.warning('请选择要操作的一行数据')
            } 
        },
        // 寄出教材
        sendTeachingMaterials() {
            if(this.currentRow) {
                if(this.currentRow.Mailstatus == 1) {
                    this.$message.warning("已同步邮寄教材，无需重复同步!")
                    return
                }
                this.$confirm('确定要寄出教材吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() =>{
                    var params = {id: this.currentRow.ID}
                    setBookLearningEnrollPostInfo(params).then(res => {
                        if(res.data.Success){
                            this.$message.success(res.data.Message)
                            this.getData()
                        }else{
                            this.$message.error(res.data.Message)
                        }
                    }).catch(() => {})
                }).catch(() => {})
            } else {
                this.$message.warning('请选择要寄出教材的数据')
            }
        },
        getTradeDataFun() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    var newArr = []
                    result.Response.forEach(item => {
                        if(item.DataType == 2) {
                            newArr.push(item)
                        }
                    });
                    this.TradeTypeArr = newArr
                }
            })
        },
        // 选择当前行
        selectCurrentRow(row){
            this.currentRow = row
        },
    },
    beforeRouteEnter(to,from,next){
        if(from.path.indexOf(to.path) == -1){
            window.sessionStorage.removeItem('pagination')
        }
        next()
    },
    created(){
        this.pages.pageIndex = Number(window.sessionStorage.getItem('pagination')) || 1
        this.getData()
        this.getTradeDataFun()
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>

</style>
<style>
    .el-tooltip__popper {
        max-width: 400px;
    }
</style>